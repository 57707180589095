import { defineStore } from 'pinia';

export const useGeneralStore = defineStore('general-store', {
    state: () => {
        return {
            menuOverlayOpen: false,
            activeSubMenu: null,
            mainNavigationData: {},
            footerData: {}
        };
    },

    getters: {
        isMenuOverlayOpen() {
            return this.menuOverlayOpen;
        },
        isSubMenuOpen() {
            return !!this.activeSubMenu;
        }
    },

    actions: {
        // Menu overlay
        toggleOverlayMenu() {
            this.menuOverlayOpen = !this.menuOverlayOpen;
        },

        activateSubMenu(value) {
            // Close the menu if it is activated when already open
            if (this.activeSubMenu === value) {
                return this.activeSubMenu = null;
            }

            // Else, activate the menu
            this.activeSubMenu = value;
        },

        closeSubMenu() {
            this.activeSubMenu = null;
        },

        closeMainMenu() {
            this.menuOverlayOpen = false;
        },

        setNavigation(navigation) {
            if (!navigation) {
                return;
            }

            if (navigation?.mainNavigation) {
                this.mainNavigationData = navigation?.mainNavigation;
            }

            if (navigation?.footer) {
                this.footerData = navigation?.footer;
            }
        },
    }
});
