<template>
    <DelayHydration>
        <footer
            class="footer layout__footer"
            data-theme-variant="theme-dark"
        >
            <div class="footer__container">
                <div class="footer__logo">
                    <TheLogo
                        :letters="`var(--color-lightest)`"
                        :filling="`var(--theme-color)`"
                    />
                    <img
                        src="~/assets/img/logos/sublogo.svg"
                        alt="Logo"
                        class="footer__logo-uva"
                    >
                    <nav
                        v-if="primaryMenuLinks && primaryMenuLinks.length"
                        class="footer__primary-navigation"
                    >
                        <ul>
                            <li
                                v-for="(link, index) in primaryMenuLinks"
                                :key="`footer-primary-${index}`"
                            >
                                <CraftLink :link="link">
                                    <BaseIcon icon="chevron-right" />{{ link.text }}
                                </CraftLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="footer__socials">
                    <h5>{{ $t('footer.findUs') }}</h5>
                    <a
                        href="https://nl.linkedin.com/company/allardpierson"
                        aria-label="Allard Pierson LinkedIn"
                    >
                        <BaseIcon
                            icon="linkedin"
                        />
                    </a>
                    <a
                        href="https://twitter.com/allard_pierson"
                        aria-label="Allard Pierson X"
                    >
                        <BaseIcon
                            icon="x"
                        />
                    </a>
                    <a
                        href="https://facebook.com/allardpiersonamsterdam"
                        aria-label="Allard Pierson Facebook"
                    >
                        <BaseIcon
                            icon="facebook"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/allardpierson/?hl=en"
                        aria-label="Allard Pierson Instagram"
                    >
                        <BaseIcon
                            icon="instagram"
                        />
                    </a>
                </div>

                <div>
                    <nav class="footer__links2">
                        <NuxtLink to="/colofon">Colofon</NuxtLink>
                        <NuxtLink to="/privacy">Privacy & Cookies</NuxtLink>
                    </nav>
                </div>

                <div class="footer__cta footer__cta--1">
                    <h3
                        v-if="footerData.titleColum2"
                        class="footer__cta--bold"
                    >
                        {{ footerData.titleColum2 }}
                    </h3>
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                        class="footer__cta--link"
                        v-html="footerData.descriptionColum2"
                    />
                    <!-- eslint-enable -->
                    <CraftLink
                        v-if="footerData?.callToActionColum2?.[0]"
                        :link="footerData?.callToActionColum2?.[0]"
                        appearance="link"
                    >
                        <BaseIcon icon="chevron-right" />{{ footerData?.callToActionColum2?.[0].text }}
                    </CraftLink>
                </div>
                <div class="footer__cta footer__cta--2">
                    <h3
                        v-if="footerData.titleColum3"
                        class="footer__cta--bold"
                    >
                        {{ footerData.titleColum3 }}
                    </h3>
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                        class="footer__cta--link"
                        v-html="footerData.descriptionColum3"
                    />
                    <!-- eslint-enable -->
                    <CraftLink
                        v-if="footerData?.callToActionColum3?.[0]"
                        :link="footerData?.callToActionColum3?.[0]"
                        appearance="link"
                    >
                        <BaseIcon icon="chevron-right" />{{ footerData?.callToActionColum3?.[0].text }}
                    </CraftLink>
                </div>
                <div class="footer__partners">
                    <h5>{{ $t('footer.partners') }}</h5>
                    <div class="footer__partners-items">
                        <img
                            v-for="(partner, index) in footerData.partners"
                            :key="index"
                            :src="partner.w320"
                            :alt="partner.title"
                        >
                    </div>
                </div>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
// import { color } from '~/assets/styles/css-variables';
import { useGeneralStore } from '~/store/general';
const generalStore = useGeneralStore();
const { footerData } = storeToRefs(generalStore);

const primaryMenuLinks = computed(() => {
    return footerData.value?.primaryNavigation ?? [];
});
</script>

<style lang="less" src="./TheFooter.less" />
