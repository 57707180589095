<template>
    <transition name="menu-overlay">
        <FocusLoop
            :is-visible="isMenuOverlayOpen"
            class="menu-overlay"
            role="dialog"
            :auto-focus="false"
            data-theme-variant="theme-dark"
        >
            <nav
                class="menu-overlay__container"
                :style="{ 'margin-top': hasBanner ? 'var(--banner-height)' : ''}"
            >
                <DnAccordion
                    :items="menuItems"
                    :scroll-into-view="false"
                    class="menu-overlay__accordion"
                >
                    <template #title="{ item }">
                        <nav>
                            {{ $t(item.title) }}
                        </nav>
                    </template>

                    <template #default="{ item }">
                        <ul class="menu-overlay__list">
                            <li
                                v-for="link in item.links"
                                :key="link.text"
                            >
                                <CraftLink
                                    :link="link"
                                />
                            </li>
                        </ul>
                    </template>

                    <template #icon>
                        <BaseIcon icon="chevron-down" />
                    </template>
                </DnAccordion>
            </nav>
        </FocusLoop>
    </transition>
</template>

<script setup>
import { FocusLoop } from '@vue-a11y/focus-loop';
import { useGeneralStore } from '~/store/general';
import DnAccordion from '@digitalnatives/accordion';

const { isMenuOverlayOpen } = storeToRefs(useGeneralStore());

defineProps({
    menuItems: {
        type: Array,
        required: true
    },
    hasBanner: {
        type: Boolean,
        default: false
    }
});
</script>

<style lang="less" src="./TheMenuOverlay.less"></style>
