<template>
    <nav
        class="navigation-bar"
        :data-theme-variant="generalStore.isSubMenuOpen || generalStore.isMenuOverlayOpen ? 'theme-dark' : null"
        @keyup.esc="generalStore.closeSubMenu"
    >
        <div class="navigation-bar__container">
            <nav class="navigation-bar__main-menu">
                <ul>
                    <li
                        v-show="generalStore.isSubMenuOpen"
                        class="navigation-bar__close-button"
                    >
                        <button
                            type="button"
                            class="navigation-bar__button navigation-bar__close-button"
                            :title="$t('submenu.close')"
                            @click="generalStore.closeSubMenu"
                        >
                            <BaseIcon icon="cross" />
                        </button>
                    </li>
                    <li
                        v-if="showEscapeHatch"
                        class="navigation-bar__escape-hatch"
                    >
                        <NuxtLink
                            class="navigation-bar__button"
                            :to="localePath('/')"
                            :title="$t('escapeHatch.title')"
                        >
                            ALLARDPIERSON
                        </NuxtLink>
                    </li>
                    <li
                        v-for="item in menuItems"
                        :key="`nav-bar-${item.title}`"
                    >
                        <button
                            type="button"
                            class="navigation-bar__button"
                            :data-active="generalStore.activeSubMenu === item.title"
                            :title="`Open menu: ${$t(item.title)}`"
                            @click="generalStore.activateSubMenu(item.title)"
                        >
                            {{ $t(item.title) }}
                        </button>

                        <SubMenu
                            :links="item.links"
                            :is-visible="generalStore.activeSubMenu === item.title"
                            class="navigation-bar__submenu"
                        />
                    </li>
                </ul>
            </nav>

            <div class="navigation-bar__actions">
                <TheLanguageSwitcher />

                <NuxtLink
                    class="navigation-bar__button dn-button--icon"
                    :to="localePath('zoek')"
                    :title="$t('search.title')"
                >
                    <span class="navigation-bar__search-label">
                        {{ $t('search.title') }}
                    </span>
                    <BaseIcon icon="search" />
                </NuxtLink>

                <TheHamburgerMenu
                    class="navigation-bar__hamburger"
                    :theme-variant="props.themeVariant"
                />
            </div>

            <!-- Todo add link to right page-->
            <BaseButton
                v-if="width > 1260 || route.path === '/' || generalStore.isMenuOverlayOpen"
                element="a"
                target="_blank"
                :href="localePath('/') === '/' ? 'https://tickets.allardpierson.nl/nl-NL/Event?eventid=329&' : 'https://tickets.allardpierson.nl/en-GB/Event?eventid=329'"
                class="navigation-bar__cta base-button__contra"
            >
                {{ $t('buyTickets') }}
            </BaseButton>

            <li
                v-else
                class="navigation-bar__escape-hatch"
            >
                <NuxtLink
                    class="navigation-bar__button"
                    :to="localePath('/')"
                    :title="$t('escapeHatch.title')"
                >
                    ALLARDPIERSON
                </NuxtLink>
            </li>
        </div>
    </nav>

    <Transition name="fade">
        <div
            v-if="generalStore.isSubMenuOpen"
            class="navbar-backdrop"
            @click="generalStore.closeSubMenu"
        />
    </Transition>
</template>

<script setup>
import {useGeneralStore} from '~/store/general';
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

const localePath = useLocalePath();

const route = useRoute();
const router = useRouter();
const generalStore = useGeneralStore();

const props = defineProps({
    menuItems: {
        type: Array,
        required: true
    },
    hasBanner: {
        type: Boolean,
        default: false
    }
});

const showEscapeHatch = computed(() => {
    return route.path !== '/' || route.path !== '/en';
});

// Close the sub menu after route changes
router.afterEach(() => {
    generalStore.closeSubMenu();
    generalStore.closeMainMenu();
});
</script>

<style lang="less" src="./TheNavBar.less"></style>
