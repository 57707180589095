<template>
    <div>
        <div
            class="layout"
            :style="{
                'padding-top': hasHomeBanner ? 'var(--banner-height)' : ''
            }"
        >
            <!--
                Skiplinks link to landmarks in the website.
                Don't forget to add the IDs below to your custom elements.
                Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
            -->
            <SkipLinks />

            <TheNavBar
                :menu-items="menuItems"
                :has-banner="hasHomeBanner"
            />

            <TheBanner
                v-if="hasHomeBanner"
                :banner="homeBanner"
            />

            <TheMenuOverlay
                :menu-items="menuItems"
                :has-banner="hasHomeBanner"
            />

            <slot />

            <TheFooter />
        </div>
    </div>
</template>

<script setup>
import NavigationQuery from '~/graphql/queries/global/Navigation.graphql';
import BannerNotification from '~/graphql/queries/global/BannerNotification.graphql';
import { useGeneralStore } from '~/store/general';

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

const generalStore = useGeneralStore();

const { variables: defaultVariables }  = useCraftGraphql();

const variables = computed(() => {
    return {
        ...defaultVariables,
    };
});

// navigation
const { data: navigation } = await useAsyncQuery({
    query: NavigationQuery,
    variables
});

generalStore.setNavigation(toValue(navigation));

const {mainNavigationData} = storeToRefs(generalStore);

const primaryMenuLinks = computed(() => {
    return mainNavigationData.value ?? [];
});

// banner
const { variables: bannerVariables } = useCraftGraphql();

// Ignore the URI in this case
delete bannerVariables.uri;

const { data: bannerResponse } = await useLazyAsyncQuery({
    query: BannerNotification,
    variables: bannerVariables
});

const homeBanner = computed(() => {
    return bannerResponse.value?.globalSets?.[0];
});

const hasHomeBanner = computed(() => {
    return bannerResponse.value?.globalSets?.[0].text !== null;
});

const menuItems = computed(() => {
    return Object.keys(primaryMenuLinks.value)
        .filter((key) => {
            return key.toLowerCase() !== 'calltoaction';
        })
        .map((key) => {
            return {
                title: 'menuTitles.' + key.toLowerCase(),
                links: primaryMenuLinks.value[key].map((link) => {
                    return {
                        text: toRef(link)?.value?.text,
                        url: toRef(link)?.value?.element?.uri,
                        target: toRef(link)?.value?.target,
                        ariaLabel: toRef(link)?.value?.ariaLabel,
                    };
                }),
            };
        });
});

useHead({
    script: [
        {
            children: `var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
    var u = '//analytics.ic.uva.nl/';
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '66']);
    g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
})();`
        },
        {
            src: '<https://www.bugherd.com/sidebarv2.js?apikey=kogit7jbxn19jb2diazatq>',
            type: 'text/javascript',
            async: true,
        },
    ],
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs
    },
    bodyAttrs: {
        'menu-open': generalStore.isOverlayMenuOpen
    }
});
</script>

<style lang="less">
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>
